@media (max-width: 1200px){ 
 
}

@media (max-width: 1120px){
.menu > li { margin: 0px 5px 0;}     
}

@media (max-width: 1080px){
.container {  padding: 0 15px;}    
.navigation{display: none}     
.nav-top{ display: block !important; }
header .container{ -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
    

.footer-top{flex-wrap: wrap}    
.footer-newletter { width: 47%; order: 2;}
.footer-nav { order: 3; width: 47%;}
.footer-logo { width: 100%; order: 1;margin-bottom: 20px}    
.banner .owl-item > div {  padding: 230px 0;}
 .banner .owl-item > div img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
    .outlet-listing-lhs{display: none}    
    .outlet-listing-rhs{width: 100%; padding: 0;}
    .filter-icon{display: inline-flex;}
    .accsec_right{padding: 30px 20px}
}

@media(max-width: 1024px) and (min-width: 580px){
    .promo-new-add .promo-new-ul{justify-content: space-between}    
    .promo-new-add .promo-new-ul li{width: 48%; margin: 0 0 20px 0}
}
@media (max-width: 840px){
    .full-login-lhs{width: 240px;}
    .full-login-rhs { padding: 60px 30px; width: calc(100% - 240px);}
    .full-login-rhs-header h3{font-size: 30px;}
}
@media (max-width: 780px){ 
h1 { font-size: 32px;}    
h2{ font-size: 28px; }   
h3 {  font-size: 23px;}        
h4 {  font-size: 21px;}    
.outlet-content { width: calc( 100% - 250px );  padding: 20px 20px 20px 20px;}
.outlet-img { width: 250px;}
.mbacc_mslidersec { display: none;}
.accmenu_sec{display: none}
.accsec_right{width: 100%;}
}
@media (max-width: 720px){ 
.header-top .container{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center}    
#details-popup {  padding: 30px 20px;}    
}
@media (max-width: 620px){ 
    .footer-logo{margin-bottom: 50px; }
.footer-newletter { width: 100%;    }  
    .footer-nav{width: 100%; padding: 40px 0 0 0;}    
    .footer-logo .float-logo{top: -20px;
    display: block;
    width: 100px;
    margin: 0 auto;}
    .float-form{position: relative; bottom: inherit;margin: 20px 0 0 0;}
    .banner .owl-item > div {
    padding: 180px 0;
}
    .skip a{color: #000;}
    .tabs-nav a{   padding: 10px 5px;}
    .odtype-select-top, .outlet-listing-filter{flex-wrap: wrap; width: 100%;}
        .desk-osb{display: none}
    .res-osb{display: block}
    .odtype-select-top > div{ width: 48%;padding: 0 }
    .outlet-listing-filter{padding: 30px 20px;}
    .odtype-select-btn .search-btn {  display: block;  text-align: center;}
    .outlet-listing-inner{padding: 30px 0 0 0;}
    .odtype-select-btn{top: 0;}
    .header-links ul li, .nav-top{margin-left: 15px}
    .header-links ul li:first-child { margin-left: 0;}
    .promo-tab ul, .vouchers-tab ul{ justify-content: space-between}
    .promo-tab ul li, .vouchers-tab ul li { width: 49%;  margin: 0 0 20px 0;}
    .white-popup .oc-buttons .button{font-size: 13px; padding: 5px 10px;}
    .full-login-lhs { width: 100%;    border-radius: 0;}
    .full-login-rhs{width: 100%; padding: 30px 30px;}
}
@media (max-width: 580px){ 
    .promo-img{width: 100%; margin: 10px 0;}   
    .home-carsouel { padding: 35px 0;}
    footer{margin: 20px 0 0 0;}
    .tabs-nav ul{flex-wrap: wrap}
    .tabs-nav li img{margin: 0 0 5px 0;}
    .tabs-nav a { padding: 10px 2px;    flex-flow: column wrap;font-size: 12px;}
    .tabs-content{border-radius: 0;     padding: 25px 20px 25px 20px;}
    .tabs-nav li.active{border-radius: 0}
    .header-links ul li.list-button, .header-links ul li.login-menu, .skip {display: none}
    .mobile-list-button { padding: 10px 15px 0px;line-height: 1;display: flex;  justify-content: space-between;}
    header{position: sticky; top: 0; background: #fff;}
    .mobile-list-button .button{ width: 100%; margin: 0;padding: 11px 3px; }
    .login-menu a{color: #000;}
    .header-bottom{padding: 10px 0;}
    .header-lhs a img{width: 50px}
    .banner .owl-item > div { padding: 120px 0;}
    .favourite img{  filter: invert(100%);  -webkit-filter: invert(100%);}
    .header-links ul li { margin: 0;}
    .outlet-listing-rhs > ul > li{flex-wrap: wrap;}
    .outlet-content { width: 100%;  padding: 15px;}
    .outlet-img { width: 100%}
    .oc-buttons{padding-top: 5px}
    .oc-buttons .button{     padding: 5px 10px;   height: 40px;}
    .showing-header h3, .oc-title h2{font-size: 18px}
   #promodetails-popup .pmc-name {
    justify-content: flex-start;
    flex-wrap: wrap;
}
    .cashback-main-top-left .pro-main-option {
    margin: 5px 0 0 0px;
    width: 100%;
    justify-content: flex-start;
}
    .half-fg .col-md-6 {  width: 100%;margin-bottom: 10px}
    .half-fg .col-md-6:last-child{margin-bottom: 0}
    .promo-new-add .promo-new-ul li{width:100%; margin: 0 0 15px 0}
    .myacc-main-div .common-inner-banner{padding: 40px 20px}
    .myacc-main-div .common-inner-banner p{font-size: 32px; margin-bottom: 5px;}
    .login-menu a:hover { color: #ec194b;}
    .tabtype-select-box label { font-size: 12px; line-height: 1.2; display: block;}
    .tabtype-select-box select { padding: 0px 0px 0px 0px; font-size: 13px;}
    .tabtype-select-button .button {font-size: 14px; padding: 5px 25px;}
    .grey-form{border-radius: 0; box-shadow: 0px 2px 7px rgb(0 0 0 / 10%);}
    .float-form{margin: 0;}
    .float-form .container{ padding: 0; }
    .pro-main-content p{padding: 5px 0}
    .header-links ul li, .nav-top{margin-left: 0;}
 }

@media (max-width: 480px){ 
    .white-popup .oc-buttons:before{display: none}
    .white-popup .oc-buttons .button{width: 100%;margin: 3px 0;}
    .promo-tab ul li, .vouchers-tab ul li {
    width: 100%;
    margin: 0 0 15px 0;}
    .mobile-list-button .button, .mobile-login-section a{font-size: 13px}
    .pmc-title{font-size: 14px;}
    .mobile-list-button { padding: 10px 5px 0px;}
}
@media (max-width: 420px){ 
     .header-bottom { padding: 5px 0 5px;}
    .login-menu a{font-size: 12px; text-transform: uppercase;}
    .favourite, .humbur a {  width: 22px;}
    .login-menu span{margin: 0 3px}
    .promo-new-add .btn-last-group .button{min-width: inherit;padding:10px 5px; height: auto;}
    .accsec_right {
    padding: 30px 15px;
}
    .myacc-main-div .common-inner-banner p {
    font-size: 26px;
}
    .myacc-main-div .common-inner-banner {
    padding: 30px 10px;
}
    .container { padding: 0 10px;}
    .tabtype-select-box { width: calc( 100% - 110px );}
    .tabtype-select-button { width: 100px;}
    .tabs-content { padding: 20px 10px 20px 10px;}
   .banner .owl-item > div { padding: 80px 0;}
    .banner .owl-item > div img{object-position: left}
    .title-filter h2{font-size: 18px;}
    .footer-newletter h4 { font-size: 17px; line-height: 1.1; margin: 0px 0px 15px 0px;}
}






















    