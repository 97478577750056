@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
html {
 -webkit-text-size-adjust: none;
}
:focus {/* remember to define focus styles! */
    outline: 0;
}
ol, ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}
table {
    border-collapse: separate;
    border-spacing: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
embed, iframe, object {
    max-width: 100%;
}

/* Global
----------------------------------------------- */
body{
    color: #4c4b4b;
    word-wrap: break-word;
    font-size:15px;
    line-height:1.3;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:#fff;
	overflow-x: hidden;
}

/* Horizontal Line */
hr {
    border-color: #eaeaea;
    border-style: solid none none;
    border-width: 1px 0 0;
    height: 0;
    margin: 0 0 0px;
}

/* Text elements */
p {
    margin-bottom: 15px;
}
strong {
    
}
cite, em, i {
    font-style: italic;
}

/* Forms */
input, textarea {
     -webkit-border-radius: 0px;
}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="number"],
input[type="file"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;

}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="file"],
textarea, select {
    padding:12px 15px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius:0px;
    height:50px;
    font-size: 14px; 
	color: #222;
    font-family: 'Poppins', sans-serif; 
}
textarea{ resize: none; height: 90px}

input[type="reset"],
input[type="button"],
input[type="submit"], .button, button {
    color: #ec194b;
    display:inline-block;
    border-radius:8px;
    margin:15px 0px 10px 0px;
    padding:11px 25px ;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background:#ffffff;
    border:2px solid #ec194b;
    font-size:15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
	text-transform: uppercase;
	line-height: 1;
	text-align: center;
    overflow: hidden;
    position: relative;
    z-index:2;
    letter-spacing: -0.35px;
    font-weight: 500;
        font-family: 'Poppins', sans-serif;
}
input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover, .button:hover {
    color:#fff; 
    background: #ec194b;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}
.button-fill{
    background: #ec194b;
    color: #fff;
}
.button-fill:hover{
    background: #fff;
    color:#ec194b; 
}

/* Links */
a {
    color: #ec194b;
    text-decoration: none;
    cursor: pointer
}
a:focus, a:active, a:hover {
    text-decoration: none;

}
a:hover{  color:#000}

/* Images */
figure {
    margin: 0;
    text-align: center;
}
img {
    max-width:100%;
    height:auto;
    vertical-align:top;
}

h1, h2, h3, h4, h5, h6 {
margin: 0px 0px 25px 0px;
padding: 0px;
font-weight: normal;
line-height: 1.3;
color: #000000;
font-family: 'Poppins', sans-serif;
}

h1 {
font-size:42px;
}
h2 {
font-size:36px;
}
h3 {
font-size:28px;
}
h4 {
font-size:24px;
}
h5 {
font-size:18px;
}
h6 {
font-size:16px;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
}
/* clearfix hack */
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

/* start commented backslash hack \*/
* html .clearfix {
    height:1%;
}
.clearfix {
    display: block;
}
/* close commented backslash hack */
.clearfix-third, .clear {
    clear: both;
}

.rel{ position:relative}
.textcenter{ text-align:center}
.textleft{ text-align: left}
.textright{ text-align: right}
*,
*:before,
*:after {
  box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.flex{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap}
.container{ padding: 0; margin: 0 auto; max-width: 1320px; width: 100%; padding: 0 20px;}

/* =======================================================================================
Header style
========================================================================================== */
header{  z-index: 9;   width: 100%; -webkit-transition: 0.3s linear all; transition: 0.3s linear all;   position: absolute;
    left: 0;
    right: 0;    top: 0; }
header.innerpage{ position: sticky; top: 0; background: #fff; -webkit-box-shadow:0px 4px 10px 0px rgb(2 2 2 / 6%); box-shadow:0px 4px 10px 0px rgb(2 2 2 / 6%); } 

header.sticky{-webkit-box-shadow:0px 4px 10px 0px rgb(2 2 2 / 6%); box-shadow:0px 4px 10px 0px rgb(2 2 2 / 6%) }
.header-bottom{ padding:15px 0px 15px; }

header .container{ -webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;  }

.header-lhs a img{ -webkit-transition: 0.3s linear all; transition: 0.3s linear all; width: 93px;  }
.header-rhs{  padding:0}
.header-links ul li{ display: -webkit-box; display: -ms-flexbox; display: flex; margin: 0 0 0 25px}
.header-links ul li a{margin: 0}
.favourite {
    width: 26px;
}
.innerpage .favourite img{ filter: invert(100%)}
.header-rhs {
    padding: 0;
    display: flex;
    align-items: center;
}
.login-menu a{ transition: all 0.3s ease;  -webkit-transition: all 0.3s ease;  -moz-transition: all 0.3s ease; position: relative; text-transform: uppercase; font-weight: 600; color: #fff; font-size: 14px }
.innerpage .login-menu a, .innerpage .login-menu span{ color: #000; }
.innerpage .login-menu a:hover{  color: #ec194b; }
.login-menu span {
    font-size: 14px;
    vertical-align: text-bottom;
    position: relative;
    top: 0;
    color: #fff;
    margin: 0 7px;
}

.header-links ul{ margin: 0; padding: 0; list-style: none; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: nowrap; flex-wrap: nowrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center}
.nav-top{  margin-left: 20px; }

footer:after, .navigation:after{ display: table; content: ""; clear: both;}

/* =======================================================================================
Humburger Aniamtion
========================================================================================== */

.humbur{display: block}
.humbur a{position: relative; display: block;    height: 16px;  width: 28px;}
.humbur a .c-trigger{ background: #283140; width: 100%; height: 2px; content: ""; display: block; position: absolute;  right: 0; margin: auto; -webkit-transition: 0.3s linear all; transition: 0.3s linear all; top: 0}
.humbur a span.middle-trigger{ top: 7px; }
.humbur a span.bottom-trigger{ top: 14px}
.humbur a:hover .c-trigger{ background: #ec194b}
.m-hidden { overflow: hidden;}
/* =======================================================================================
Nav style
========================================================================================== */

.menu {margin: 0;padding:0; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}
.menu > li {margin:0px 20px 0;position: relative;list-style: none;vertical-align: middle;text-align: center; z-index:9; line-height: normal; float: left }
.menu > li:last-child{ margin-right: 0px}
.menu > li > a { color:#fff; font-size:14px; padding: 10px 5px 10px; line-height:1; display: block;  transition: all 0.3s ease;  -webkit-transition: all 0.3s ease;  -moz-transition: all 0.3s ease; position: relative; text-transform: uppercase; font-weight: 600  }
.innerpage .menu > li > a{ color: #000; }
.menu > li > a span{ position: relative; display: inline-block}
/*.menu > li > a span:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -7px;
    background: #408bf9;
    height: 2px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.menu > li > a:hover span:before, .menu > li.active > a span:before, .menu > li:hover > a span:before{     left: 0; right: 0;}*/
.menu > li > a:hover, .menu > li.active > a, .menu > li:hover > a {color: #ec194b}
.menu > li:hover > ul, .menu ul li:hover > ul{display: block;}
.menu ul {margin:0; padding:0;background:#fff ; z-index: 99;width:200px;position: absolute; left:0px; top:100%;display: none;    -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1); }
.menu ul ul {left: 100%;top: 0;}
.menu ul li {margin: 0; float: none;display: block;line-height: normal;text-align: left;position: relative;-webkit-transition: all .25s ease; }
.menu ul li a{display: block; font-size:14px;border-bottom: 1px solid rgba(0, 0, 0, 0.06); padding: 8px 10px;color:#000; font-weight: 500 }
.menu > li > ul > li:last-child > a, .menu > li > ul > li > ul li:last-child > a{ border-bottom: 0;  }
.menu ul li a:hover, .menu ul li a.active, .menu ul li:hover > a{  color: #fff; background: #ec194b }
.menu ul, .menu a{-webkit-transition: all 0.5s ease;transition: all 0.5s ease;}
.menu > li:hover > ul, .menu ul li:hover > ul{display: block;-webkit-animation:menu_up ease-out 0.4s 0s;-webkit-animation-fill-mode:both; -webkit-animation:menu_up ease-out 0.4s 0s; animation:menu_up ease-out 0.4s 0s; -webkit-animation-fill-mode:both; animation-fill-mode:both;}
@-webkit-keyframes menu_up{
	0%{-webkit-transform:translateY(50px);opacity:0;}
	100%{-webkit-transform:translateY(0px);opacity:1;}
}
@keyframes menu_up{
	0%{-webkit-transform:translateY(50px);transform:translateY(50px);opacity:0;}
	100%{-webkit-transform:translateY(0px);transform:translateY(0px);opacity:1;}
}
/*.sticky-header{ position: fixed; background: #000; padding:30px 30px 30px; }*/

.navigation {
    margin-right: 60px;
}

.alert_header {
    background:#000;
    color: #fff;
    font-size: 22px;
    padding: 14px 14px;
    text-align: center;
    text-transform: uppercase;   
    font-weight: bold;
}
.alert_body {text-align: center; padding: 20px;}
.alt_btns a {margin: 0 5px; padding: 11px 20px 11px 20px; }
.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px;
}
.warning_popup{
    padding: 0
}


/* =======================================================================================
Banner style
========================================================================================== */
.banner{position: relative; z-index: 3;}
.promo-banner {
    margin: 20px 0 20px;
}
.promo-banner .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.promo-img{width: 48%}
.promo-img img{width: 100%;}
.home-carsouel .owl-carousel .owl-stage-outer{ padding: 30px 0 30px; }
.pro-main {
    border: 1px solid #f3f3f3;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 11px 18px rgb(2 2 2 / 6%);
    position: relative;
    height: 100%;
    background: #fff;
}
.pmc-name {
    font-size: 13px;
    font-weight: 500;
    color: #4c4b4b;
    margin: 0 0 5px 0;
}
.pmc-name a{
    color: #4c4b4b;
}
.pmc-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 5px 0;
}
.pmc-valid{
    color: #8689a4;
    font-size: 12px;
}
.pmc-button{ position: absolute; left: 10px; right: 10px; bottom: 20px;line-height: 1;}
.pmc-button .button{margin: 0 auto; width: 100%; max-width: 140px;padding: 11px 10px;}
.pro-main-content { padding: 15px 10px 75px 10px;}
.pro-main-option {display: flex;justify-content: center;flex-wrap: wrap;margin: 10px 0}
.pro-main-option span {
    font-size: 12px;
    background: #e8e8e8;
    padding: 5px 10px;
    line-height: 1;
    color: #0c0b0b;
    border-radius: 12px;
    margin: 0 5px 5px 0;
    font-weight: 500;
}
.pro-main-content p {
    color: #4c4b4b;
    font-size: 12px;
    line-height: 1.2;
    padding: 5px 10px;
}
.home-carsouel-title {display: flex;justify-content: space-between;border-bottom: 2px solid #f3f3f3;padding: 0 0 10px 0;align-items: flex-end;}
.title-filter img {
    margin-right: 10px;
    opacity: 0.6;
}
.title-filter{
    display: flex;
    align-items: center;
}
.title-filter h2 {
    margin: 0;
    font-size: 22px;
    font-weight: bold;

}
.title-filter h2 span{ font-weight: normal;}

.home-bottom-link {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0 0 0;
}
.home-bottom-link a{ 
    font-size: 14px;
    letter-spacing: 3.5px;
    position: relative;
    text-transform: uppercase;
    display: inline-flex;
    padding-right: 35px;
       
   }
.home-bottom-link a:after{position: absolute; right: 0; top: 5px; background: url(../images/black-right-arrow.svg) no-repeat; content: ""; width: 24px; height: 11px;    background-size: contain;}
.home-bottom-link a strong{   font-weight: bold;margin-right: 6px;}

.latest-promo .pmc-button .button, #details-popup .promo-tab .pmc-button .button{ border-color: #13a472; color: #13a472; }
.latest-promo .pmc-button .button:hover, #details-popup .promo-tab .pmc-button .button:hover{ border-color: #13a472; color: #fff; background: #13a472 }
.latest-voch .pmc-button .button, #details-popup .vouchers-tab .pmc-button .button{ border-color: #167cef; color: #167cef; }
.latest-voch .pmc-button .button:hover, #details-popup .vouchers-tab .pmc-button .button:hover{ border-color: #167cef; color: #fff; background: #167cef }

.home-carsouel .owl-stage {
    display: flex;
    margin: 0 2px;
}
.home-carsouel .owl-item > div {
    height: 100%;
}
.home-carsouel .owl-dots {position: relative;left: 0;right: 0;margin: auto;display: flex;justify-content: center;bottom: 0;}
.home-carsouel .owl-dots .owl-dot {width: 11px;height: 11px;border: 1px solid #bbb8b8;border-radius: 50%;margin: 0 3px;}
.home-carsouel .owl-dots .owl-dot.active{ border-color: #000; }
.pro-main-img {position: relative;overflow: hidden;}

.pro-main-img:before {display: block;content: "";padding-bottom: 58.36585%;width: 100%;}

.pro-main-img > img {position: absolute;left: 0;right: 0;top: 0;bottom: 0;margin: auto;height: 100%;object-fit: cover;width: 100%}

.latest-cash {
    background: #fdeff2;
    padding: 60px 0;
}
.home-carsouel {
    padding: 60px 0;
}
.latest-cash .home-carsouel-title{
    border-color: #f1dddd;
}



.grey-form {
    background: #f0f0f0;
    box-shadow: 0 8px 10px rgb(32 32 32 / 10%);
    overflow: hidden;
    border-radius: 20px;
    max-width: 650px;
    width: 100%;
}
.float-form {position: absolute;bottom: 40px;z-index: 9;left: 0;right: 0;}
.float-form .container {
    display: flex;
    align-items: flex-end;
    flex-flow: column wrap;
}

.skip {margin: 10px 0 0 0;}

.skip a {color: #fff;font-size: 14px;font-weight: 600;border-bottom: 2px solid #8a8e8f;}
.tabs-nav ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.tabs-nav li {
    display: inline-flex;
    background: #f0f0f0;
    list-style: none;
    width: 25%;
    justify-content: center;
}

.tabs-nav a {
  display: flex;
  padding:14px 15px;
  font-weight: 600;
  color: #241e21;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    width: 100%;
    line-height: 1;
}
/* Active tab */

.tabs-nav li.active {
  background: #FFF;
  border-radius: 20px 20px 0 0;
}

.tabs-nav li.active a {
  opacity: 1;
}
.tabs-nav li img{margin-right: 5px;}



/* Tab content */

.tabs-content {
    padding: 25px 30px 35px 30px;
    background: #FFF;
    overflow: hidden;
    border-radius: 0 20px 0px 0px;
}


/* Hide all but first content div */

.tabs-content div:not(:first-child) {
  display: none;
}



.tabtype-select {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tabtype-select > div {
    display: block !important;
}
.tabtype-select-box label {font-weight: 500;color: #d0cdce;font-size: 13px;}

.tabtype-select-box select {border: 0;border-bottom: 2px solid #db1746;margin-bottom: 0;height: auto;padding: 5px 5px 2px 0px;font-size: 16px;font-weight: 500;}

.tabtype-select-button .button {
    margin: 0;
    width: 100%;
    font-size: 15px;
    background: #db1746;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tabtype-select-box {width: calc( 100% - 165px );}
.tabtype-select-button{ width: 150px; }




/* =======================================================================================
Responsive Navigation
========================================================================================== */
.overlay {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .35s, visibility .35s, height .35s;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
	z-index: 999;
	color: #fff;
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.pattern{  padding: 80px 15px 30px;    height: 100%; width: 100%; overflow-y: auto}
.device_nav{ padding: 0}
.device_nav li{ list-style: none; text-align: center}
.device_nav > li > a{ color: #222;  font-size: 22px;padding:5px 0; display: inline-block; transition: 0.3s linear all; -webkit-transition: 0.3s linear all; text-transform: uppercase;   letter-spacing: 1px; }
.device_nav li a:hover{ color: #408bf9}
.device_nav li ul{     display: none;  margin: 0;  padding: 15px 10px;}
.device_nav > li ul li a{ color: #222;   display: inline-block; line-height: 1.3; padding: 5px 0; letter-spacing: 0.5px;   font-size: 19px;  }
.device_nav > li ul li li a{ font-size: 18px }
.device_nav  li  a.subicon{    padding:1px 8px; text-align: center; vertical-align: middle; margin-left:5px; width: 30px; height: 30px; line-height: normal}
.subicon:after {   content:"\f0d7";  font-family: 'FontAwesome';  display: block;  -webkit-transition: 0.4s linear all; transition: 0.4s linear all; color: #222}
.subicon.open:after { -webkit-transform: rotate(180deg); transform: rotate(180deg)}
.over-close{ width: 25px;position: absolute; top: 20px; right: 20px; cursor: pointer; -webkit-transition: 0.3s linear all; transition: 0.3s linear all}
.over-close:hover{ opacity: 0.6 }
.hassub_menu.active > a{ color: #408bf9 }
.nav-logo { margin: 0 0 30px 0;}
.nav-logo img {
    width: 60px;
}
.device_nav > li {
     opacity: 0;
    -webkit-transform: translate3d(0, -36px, 0);
    transform: translate3d(0, -36px, 0);
}
.overlay.open .device_nav > li:nth-child(1){
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
}
.overlay.open .device_nav > li:nth-child(2){
-webkit-transition-delay: 0.35s;
transition-delay: 0.35s;
}
.overlay.open .device_nav > li:nth-child(3){
-webkit-transition-delay: 0.45s;
transition-delay: 0.45s;
}
.overlay.open .device_nav > li:nth-child(4){
-webkit-transition-delay: 0.55s;
transition-delay: 0.55s;
}
.overlay.open .device_nav > li:nth-child(5){
-webkit-transition-delay: 0.65s;
transition-delay: 0.65s; 
}
.overlay.open .device_nav > li:nth-child(6){
-webkit-transition-delay: 0.75s;
transition-delay: 0.75s; 
}
.overlay.open .device_nav > li:nth-child(7){
-webkit-transition-delay: 0.85s;
transition-delay: 0.85s; 
}
.overlay.open .device_nav > li:nth-child(8){
-webkit-transition-delay: 0.95s;
transition-delay: 0.95s; 
}
.overlay.open .device_nav > li {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

@media (max-width: 780px){
.device_nav li ul{ padding: 10px }    
.device_nav li a{  font-size: 20px;    padding: 5px 0; }
.device_nav > li ul li a{  font-size: 17px }
.device_nav > li ul li li a{ font-size: 16px }
}
@media (max-width: 480px){
.device_nav li a{  font-size: 18px;  }
}

/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox],
input[type=radio] {
    --active: #282728;
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, .3);
    --border: #d3cdcd;
    --border-hover: #000;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #999;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 20px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type=checkbox]:after,
input[type=radio]:after {
    content: "";
    display: block;
    left: -1px;
    top: -1px;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked,
input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled,
input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked,
input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label,
input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled),
input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus,
input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch),
input[type=radio]:not(.switch) {
    width: 20px;
  }
  input[type=checkbox]:not(.switch):after,
input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked,
input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label,
input[type=radio] + label {
    line-height: 21px;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px !important;
  }

  input[type=checkbox]:not(.switch) {
    border-radius: 3px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 10px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type=radio]:checked {
    --s: .5;
  }
}


.outlet-listing-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px 0 0;
    align-items: flex-start;
}
.outlet-listing-lhs {
    width: 240px;
    position: sticky;
    top: 100px;
}
.outlet-listing-rhs {
    width: calc(100% - 240px);
    padding: 0 0 0 35px;
}
.filter-option {
    margin: 0 0 25px 0;
    border-bottom: 1px solid #e9e9e9;
    padding: 0 0 25px 0;
}

.filter-option ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.filter-option ul li {
    margin: 0 0 12px 0;
    position: relative;
    min-height: 24px;
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.filter-option ul li:last-child {
    margin-bottom: 0
}

.filter-option h5 {
    font-size: 15px;
    text-transform: inherit;
    font-weight: 600;
    margin-bottom: 20px;
}

.filter-option ul li input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 1px;
}
.filter-option ul li input[type=checkbox]:not(.switch):after {
    display: none
}

.filter-option ul li label {
    margin-left: 0 !important;
    margin-bottom: 0;
    color: #000;
    display: block;
    line-height: 1;
}
.outlet-listing-rhs > ul{padding: 0; margin: 0; list-style: none}
.outlet-listing-rhs > ul > li{ display: flex; justify-content: space-between; background: #fff; border: 1px solid #ebe8e8; box-shadow: 0 2px 16px rgba(0,0,0,0.14); border-radius: 10px; overflow: hidden; margin: 0 0 15px 0;}
.outlet-img{width: 290px; border-radius: 10px; overflow: hidden;position: relative}
.outlet-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.outlet-content{ width: calc( 100% - 290px ); padding: 20px 40px 20px 20px;   }
.oc-title h2 {
    font-weight: bold;
    font-size: 21px;
    margin: 0 0 15px 0;
    line-height: 1;
}
.oc-title h2 a{
    color: #000;
}
.oc-title h2 a:hover{
    color: #ec194b;
}
.oc-desc {
    color: #868484;
    font-size: 12px;
    line-height: 1.5;
    -webkit-box-orient: vertical;
display: -webkit-box;
overflow: hidden; -webkit-line-clamp: 2;
}
.oc-desc p{margin: 0}
.oc-title ul {list-style: none;padding: 0 0 5px 0;}
.oc-title ul li {display: inline-flex;align-items: center;font-size: 12px;color: #363637;margin: 0 20px 10px 0;line-height: 1;}
.oc-title ul li img {margin-right: 5px;}
.outlet-content .pro-main-option {
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 5px;
}
.oc-buttons .button {display: inline-flex;align-items: center;justify-content: center;margin: 5px 10px 0px 0;padding: 5px 15px;height: 44px; background: #282728; color: #fff; border-color:#282728 }
.oc-buttons {display: flex;flex-wrap: wrap;padding-top: 15px}
.oc-buttons .red-btn{ background: #db1746; border-color: #db1746 }
.oc-buttons .green-btn{ background: #13a472; border-color: #13a472 }
.oc-buttons .button:hover{ background: #db1746; border-color: #db1746; color: #fff; }
.oc-buttons .button img {
    margin-right: 5px;
}
.like img {filter: invert(100%);
    -webkit-filter: invert(100%);width: 15px !important;}

.like {position: absolute;top: 10px;right: 10px;background: #fff;display: flex;justify-content: center;align-items: center;width: 26px;height: 26px;border-radius: 50%;z-index: 1}
.like:hover, .like.active{ background: #db1746 }
.like:hover img, .like.active img{  filter: invert(0%);
    -webkit-filter: invert(0%); }

footer { position: relative; background: #000000; padding: 45px 0 0 0; color: #fff;margin: 80px 0 0 0;}
.footer-top { padding: 0 0 30px 0; display: flex; justify-content: space-between;}
.footer-newletter { width: 32.03125%;}
.footer-logo { width: 28.90625%;text-align: center}
 .footer-logo .float-logo{
    position: relative;
    top: -80px;
}
.footer-logo .float-logo img {box-shadow: 0 2px 111px rgb(0 0 0 / 37%);border-radius: 12px;overflow: hidden; width: 133px;}
.footer-nav { width: 29.6875%; display: flex; flex-wrap: wrap;justify-content: space-between;}
.footer-nav-inner { width: 49%;}
.footer-newletter span{color: #fff; font-size: 12px;display: block;margin: 0 0 5px 0; font-weight: 600}
.footer-newletter h4{ color: #fff; font-size: 20px; text-transform: inherit;line-height: 1.4;}
.f-newletter-form{position: relative}
.f-newletter-form input[type="text"] {
    background: none;
    border: 0;
    border-bottom: 2px solid #a9a9a9;
    padding-left: 2px;
    padding-right: 34px;
    color: #fff;
    border-radius: 0;
}
.f-newletter-form .button {
    position: absolute;
    right: 5px;
    top: 20px;
    margin: 0;
    background: none;
    padding: 0;
    border-radius: 0;
    width: 21px;
    height: 14px;
    font-size: 0;
    border: 0;
}
.f-newletter-form .button:after{ background: url(../images/red-arrow.svg) no-repeat ; width: 20px; height: 12px; content: "";    display: block; position: absolute; top: 0; left: 0; }
.f-newletter-form input[type="text"]::placeholder {color: #fff;}
.f-newletter-bottom label { font-size: 11px; color: #d6d6d6;line-height: 16px !important;}
.f-newletter-bottom { position: relative;padding-left: 24px;}
.f-newletter-bottom input[type="checkbox"] { position: absolute; left: 0; top: 4px;background: #000;border-color: #ec194b}
.f-newletter-bottom input[type=checkbox]:not(.switch):checked{ background: #ec194b; }
.footer-nav h5{ color: #fff;     font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 600; letter-spacing: 5px;margin-bottom: 20px; text-transform: uppercase; }
.footer-nav ul{padding: 0; margin: 0; list-style: none;}
.footer-nav ul li a{ color: #fff;display: inline-flex; margin-bottom: 8px;}
.footer-nav ul li a:hover{ color: #ec194b; }
.social-media a img { height: 33px;width: 33px; }
/*.social-media a:first-child img{ max-width: 14px }
.social-media a:nth-child(2) img{ max-width: 27px }
.social-media a:last-child img{ max-width: 40px }*/
.social-media a{display: block; margin-right: 20px}
.social-media {margin: 20px 0 0 0; display: flex; justify-content: center;}
.social-media a:last-child{margin-right: 0}
.social-media img:hover{-webkit-transform: scale(1.1);transform: scale(1.1);transition: all .2s cubic-bezier(.63,.05,.43,1.7);}
.copyright-section {
    background: #191919;
    padding: 20px 10px
}
.copyright-section p {
    font-size: 14px;
    color: #838383;
    text-align: right;
    margin-bottom: 0;
}
.mobile-footer-nav {
    display: none;
}
.mobile-footer-nav .accordion {
    border: 0;
    border-radius: 0;
}
.mobile-footer-nav .accordion button.title {
    margin: 0;
    border: 0;
    box-shadow: none;
    background: #1d1d1d;
    border-radius: 0 !important;
        font-size: 15px;
    color:#ec194b;
}
.mobile-footer-nav .accordion .accordion-item .title:after {
    content: "\f107";
    font-size: 25px;
    color: #ed3650;
    margin-left: 0px;
    font-family: 'FontAwesome';
    position: absolute;
    right: 20px;
    top: 12px;
    transition: 0.3s linear all;
    transform: rotate(0deg);
}
.mobile-footer-nav .accordion .accordion-item.active .title:after{ content: "\f107";transform: rotate(180deg);  }
.accordion .accordion-item.active .title{background-color: #111111;}
body .accordion .accordion-item.active .panel{ max-height: 2000px }
.mobile-footer-nav .accordion .accordion-item .panel {
    background-color: #222;
    border:0;
    border-radius: 0px;
    box-shadow:none;
}
.mobile-footer-nav .accordion .accordion-item.active .panel {
    max-height:800px;
    transition: max-height 0.5s linear;
}
.mobile-footer-nav .accordion .accordion-item .panel ul{ padding:0; list-style: none;  padding: 20px;}
.mobile-footer-nav ul li a {
    color: #fff;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}
.panel { margin-bottom: 10px;}
#scrollbutton {
    position: fixed;
    bottom: 80px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #252525;
    display: flex;
    position: relative;
    color: #ec194b;
    overflow: hidden;
    border-radius:6px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.social-media-parent h6 {color: #fff;font-weight: 500;font-size: 15px;margin-bottom: 10px;}
.detail-popup{max-width: 640px; padding: 0; border-radius: 11px; overflow: hidden}
.detail-popup .pmc-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.alert_footer a {
    background: #db2740;
    display: block;
    text-align: center;
    padding: 18px 10px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1;
    font-size: 17px;
}
.detail-popup .alert_body {
   max-width: 400px;
    margin: 40px auto 10px auto;
   position: relative;
    padding: 0;
}
.detail-popup .alert_body p{
    color: #000;
    font-size: 18px;
} 
.detail-popup .alert_body p span{ font-weight: bold;}
.detail-popup .alert_body  .input-copy-code {
    border: 2px solid #e2e2e2;
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    height: 40px;
    margin-bottom: 0px;
    background: #e5e4e4;
    border-radius: 10px;
    padding: 3px 20px;
    width: calc( 100% - 130px );
    display: block
}  
.detail-popup .alert_body .button {
    background: #13a472;
    color: #fff;
    border-color: #13a472;
    display: flex;
    width: 140px;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 5px;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 5px;
        position: absolute;
    right: 0;
    top: 0;
}

.list-button .button{padding: 14px 20px;}
.alert_header {
    padding:10px 30px 10px 30px;
    position: relative;
}
.alert_header h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 10px 0;
}

span.expiry-date-info {
    font-style: italic;
    color: #777;
    font-size: 15px;
    font-weight: 300;
}
span.copied-code-msg {
    width: 100%;
    clear: both;
    text-align: center;
    margin: 10px 0 0 0;
    color: #09bb1a;
    font-weight: 500;
}
.offer-tag-txt {
    position: absolute;
    left: -9px;
    top: 20px;
    background: #f2e513;
    color: #000000;
    font-size: 22px;
    padding: 5px 20px;
    font-weight: 700;
    margin: 0;
}

.offer-tag-txt:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: -8.5px;
    left: 0.1px;
    border-bottom: 9px solid #e1d401;
    border-left: 9px solid transparent;
}

.detail-popup .mfp-close{
    margin: 0;
    width: 30px;
    height: 30px;
    background: #000;
    color: #fff;
    opacity: 1;
    top: 8px;
    right: 8px;
    line-height: 30px;
    border-radius: 8px;
    font-size: 0;
}
.detail-popup .mfp-close:before, .detail-popup .mfp-close:after {
  position: absolute;
  left: 0;
  content: ' ';
  height:18px;
  width: 1px;
  background: #fff;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
}
.detail-popup .mfp-close:before {
  transform: rotate(45deg);
}
.detail-popup .mfp-close:after {
  transform: rotate(-45deg);
}


.mobile-list-button{display:none;}
.showing-header{margin: 0px 0px 25px 0px;     display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;}
.filter-icon {
    display:none;
    align-items: center;
    cursor: pointer;
    height: 30px;
    width: 30px;
}
.filter-icon img{
    width: 30px;
    height: 30px;
}

.popup-filter {
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 991;
    padding: 0;
    transform: translateY(100%);
    transition: 0.3s linear all;
    right: 0;
    border-radius: 15px 15px 0 0;
    overflow-y: auto;
    bottom: 0;
}

.popup-filter.active {
    transform: translateY(0%);
}

.button-bottom {
    background: #FCFCFC;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    padding: 15px;
    position: sticky;
    bottom: 0;
    z-index: 1;
}

.button-bottom a {
    width: 49%;
    margin: 0;
    padding: 13px 20px;
    font-size: 17px;
    font-weight: 600
}
.button-bottom a:last-child{
    background: #ec194b;
    color: #fff;
}
.popup-filter-header {
    position: sticky;
    text-align: center;
    padding: 30px 30px 20px;
    top: 0;
    background: #fff;
    z-index: 1;
}
.popup-filter .filter-option {
 padding: 0 30px 25px 30px;
}
.popup-filter-header h3 a {
    position: absolute;
    right: 0;
    top: 5px;
}
.popup-filter-header h3 {
    font-size: 20px;
    margin: 0;
    position: relative;
}
.popup-filter-header h3 a img {
    width: 14px;
}
.showing-header h3 {font-size: 20px;margin: 0}
.showing-header h3 strong {font-weight: 600;}
.outlet-listing-lhs > h4 {font-size: 15px;font-weight: 600;position: relative;}
.outlet-listing-lhs > h4:after {height: 1px;width: 100%;background: #e9e9e9;content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);}
.outlet-listing-lhs > h4 span {background: #fff;position: relative;z-index: 1;display: inline-flex;padding: 0 20px 0 0;}
.outlet-listing-filter {display: flex;justify-content: space-between;align-items: flex-end;margin: 0px 0 40px 0;padding: 40px 30px;box-shadow: 0 1px 15px rgb(0 0 0 / 10%);border-radius: 15px;}
.outlet-listing-filter select {
    margin-bottom: 0;
    border: 0px;
    border-bottom: 2px solid #e2dfdf;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 10px 5px 5px;
    height: 40px;
}
.odtype-select-top .react-datepicker__input-container input[type="text"]{ margin: 0;  border: 0px;
    border-bottom: 2px solid #e2dfdf;
    font-size: 15px;
    font-weight: 500; } 
.odtype-select-top .react-datepicker-wrapper{ display: block}

.odtype-select {width: 35%;}
.odtype-select-one {width: 31%;}
.odtype-select-two {width:31%;}
.odtype-select-top { width: calc( 100% - 100px );}
.odtype-select-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.odtype-select-btn {
    width: 100px;
    padding-left: 30px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    top: -2px;
}
.odtype-select-btn .search-btn{
    border-bottom: 2px solid #ec194b;
}
.odtype-select-btn .search-btn:hover{ border-color: #000; color: #000; }

.res-osb{display: none;}
body .react-datepicker{ font-family: 'Poppins', sans-serif; }
body .react-datepicker__navigation{margin: 0; border-radius: 0}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
    background-color: #ec194b !important;
}
body .react-datepicker-popper {
    z-index: 2;
}


.cust-login-popup,
.cust-signup-popup,
.cust-forgot-password-popup, .bus-login-popup,
.bus-signup-popup,
.bus-forgot-password-popup, .changepw_popup,
.warning-login-popup
{
  max-width: 830px;
  margin: 15px auto;
  width: 100%;
  background: #f6f6f6;
  position: relative;
  color: #000;
  border: 0;
      border-radius: 15px;
    overflow: hidden;    
}
.warning-login-popup {
    max-width: 420px;
}
.warning-login-popup .full-login-rhs {
    width: 100%;
    padding: 60px 30px 30px 30px;
}
.warning-login-popup .extra-btn {
    display: flex;
    justify-content: space-between;
}
.warning-login-popup .extra-btn button {
    width: 48%;
}
.warning-login-popup .extra-btn button:first-child {
    background: #fff;
    border-color: #ec194b;
    color: #ec194b;
}
.cust-login-popup, .cust-signup-popup, .cust-forgot-password-popup, .changepw_popup{
    max-width: 530px;

}
.changepw_popup { padding: 0;}

.full-login-new {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #f6f6f6;    
}
.full-login-lhs {
  background: #ec194b;
  padding: 30px 30px;
  text-align: center;
  width: 320px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;    
}

.full-login-rhs-header {
  padding: 0 0 25px 0;
}
.full-login-rhs-header h3 {
  font-size: 34px;
  margin-bottom: 10px;
  line-height: 1;
  color: #000;
  font-weight: bold;
}

.full-login-rhs-header p {
  margin-bottom: 0;
  font-size: 14px;
}
.full-login-rhs {
  margin: 0 auto;
  padding: 60px 60px;
  text-align: center;
  width: calc(100% - 320px);
}
.full-login{ width: 100%; border-radius: 10px; }

.full-login-rhs h4 {
  display: none;
}
.full-login-rhs .custom_checkbox_content {
    text-align: left;
    display: flex;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    min-height: 20px;
    align-items: center;
    margin: 15px 0 0 0;
}
.full-login-rhs .custom_checkbox_content input {
    position: absolute;
    left: 0;
    top: 0px;
}
.full-login-rhs .open-popup-link {
  font-weight: normal;
  text-transform: uppercase;
  color: #000;
  font-size: 15px;
    border-bottom: 2px solid #aeaeae;
    display: inline-flex;
        margin: 5px 0 15px 0;
}
.full-login-rhs .open-popup-link:hover {
  color: #ec194b;
}



.full-login-rhs .sign_reg {
  display: inline-block;
  vertical-align: middle;
}
.full-login-rhs .sign_reg p {
  margin-bottom: 0;
  color: #595656;
}
.full-login-rhs .sign_reg a {
  color: #595656;
  text-decoration: underline;
}
.full-login-rhs .custom_checkbox span {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
  padding-left: 20px;
}
.full-login-rhs .custom_checkbox span:before {
  border-radius: 0;
  width: 16px;
  height: 16px;
}
.full-login-rhs .custom_checkbox input[type="checkbox"]:checked + span:before {
  
  border: 0;
}
.full-login-rhs .button {
    width: 100%;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
        padding: 13px 12px;
    background: #000;
    color: #fff;
    border-color: #000
}
.full-login-rhs .login_pop_sub .button{
     background: #ec194b;
    border-color: #ec194b;
} 
.full-login-rhs .button:hover{
    background: #fff;
    border-color: #ec194b;
    color: #ec194b;
}

.full-login-rhs .form-control{
    border: 1px solid #cbcbcb;
    height: 44px;
        padding: 7px 10px;
}

span.error {
  background: #ffbaba;
  color: #d8000c;
  padding: 5px 10px;
  font-size: 11px;
  position: relative;
  display: inline-block;
  font-weight: normal;
  z-index: 4;
  line-height: 1.1;
}

span.error:before {
  content: "";
  border-bottom: 5px solid #ffbaba;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -5px;
  width: 0;
}

.mfp-close {
    width: 26px;
    height: 26px;
    line-height: 26px;
   background: #000 !important;
    right: 5px;
    top: 5px;
    border-radius: 5px;
    color: #fff !important;
    margin: 0;
    opacity: 1;
}
.innersection_wrap.myadmin_wrap{ padding: 30px 0 10px 0;position: relative;  }


/*--My Account--*/
.mainacc_menusec {
    margin: 0 0 40px;
    display: flex;
	flex-wrap: wrap;
}
.mainacc_menuout {
    width: 215px;
    display: inline-block;
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    height: 100%;
}
.mainacc_menucontent {
    min-height: 100vh;
	background:#fff;
}
.mainacc_menulist {padding: 0; list-style: none; margin: 0; box-shadow:-4px 8px 16px -2px rgb(0 0 0 / 18%);
}
.mainacc_menulist > li {position: relative;}
.mainacc_menulist > li > a {
    background: #f1f3fc;
    color: #000;
    font-size: 16px;
    padding: 14px 15px 14px;
    display: block;
    position: relative;
    text-align: left;
    font-weight: 500;
}
.mainacc_menulist > li:hover > a, .mainacc_menulist > li.active > a { background: #0a1232; color: #fff;}
.mainacc_menulist > li:hover #masterCount, .mainacc_menulist > li.active #masterCount {
    background: #fff;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #000;
}	

.mbacc_mslidersec {display: none;}
.mainacc_moblink {display: none;}
.myacc_hea {position: relative; margin: 0 0 14px;}
.myacc_hea::after,
.myacc_pg::after {
    display: block; content: ""; clear: both;
}
.myacc_hea h3 {font-size: 27px; margin-bottom: 5px;}
.myacc_hea p {margin-bottom: 25px; color: #5b5b5b;}
.myacc_hea_inner {margin-top: 8px;}

.account_sec {display: -webkit-box; display: -ms-flexbox; display: flex;flex-wrap: wrap;}
.accmenu_sec {background: #fff; width: 210px; position: relative; color: #fff; }
.accprofile {padding: 26px 10px 0px;}
.accprofile_img {
    width: 140px; height: 140px; border: 2px solid rgba(0, 0, 0, 0.2); line-height: 134px; border-radius: 50%;
    margin: 0 auto 0; overflow: hidden;
}
.accprofile_img img {vertical-align: middle;}
.accprofile_info p {font-size: 15px; color: #000; font-weight: normal; margin: 0;}
.accpoints_sec {padding: 15px 7px 0px;}
.accpoint_tt span {background: #fff; padding: 0 4px; position: relative; display: inline-block;}
.accearn_points {background: rgba(0, 0, 0, 0.5); padding: 15px 15px 10px;}
.accearn_pointtop {position: relative; padding-left: 52px;}
.accearn_pointtop i {position: absolute; left: 0; top: 7px;}
.accearn_pointtop h4 {font-size: 15px; margin: 0; color: #fff;}
.accearn_pointtop span {font-size: 45px; display: inline-block; line-height: 0.8; color: #ffc800;}
.accearn_pointtop sub {font-size: 60%; bottom: 0;}
.accearn_points p {font-size: 13px; margin: 0;}
.accpoint_ec {position: absolute; right: 13px; bottom: 8px;}
.accsec_right {
    width: calc(100% - 210px); width: -webkit-calc(100% - 210px); display: inline-block; padding: 40px 40px; border: 3px solid #f4f3f3; box-shadow: 0 1px 21px rgba(0,0,0, 0.08)
}
.accsec_right h1 {font-size: 28px; color: #040507; margin-bottom: 15px;}
.accsec_right .welcome-title h1 {line-height: 1; display: flex; justify-content: left; align-items: center; margin:5px 0px 20px; font-weight: bold}
.accsec_right .welcome-title h1 img{margin: 0px 0 0 15px; position: relative; top: 2px;}
.accsec_right .welcome-title h1 > div {text-align: center;}
.accsec_right .welcome-title h1 > div span {color: #282828; font-size: 18px; font-weight: 500; }
.accsec_right p {color: #000000; font-size: 17px; text-align: left;}
.acc-inform .form-group {margin-bottom: 10px;}
.other-add-body {
    height: 175px; border: 1px solid #f1efef; border-radius: 7px; padding: 16px 14px 0px 14px; background: #fff;
}
.other-add-row {
    display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;
    -webkit-box-align: center; -ms-flex-align: center; align-items: center; list-style: none;
    width: 100%; padding-left: 0; margin-bottom: 0;
}
.other-add-row li {
    width: 32.1875%; margin-right: 1.6666666666666666%; position: relative; margin-bottom: 10px;
}
.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}
.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}
.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}
.other-add-row span:hover {opacity: 0.5;}
.setting_menu_list {border: 1px solid #e8e8e8; }
.setting_menu_list > ul {list-style: none; padding: 0; margin-bottom: 0;}
.setting_menu_list > ul li {position: relative;}
.setting_menu_list > ul > li > a {
    padding: 12px 20px 12px 20px;
    color: #000;
    display: block;
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: 14px;
    
}
.setting_menu_list > ul li a:hover, .setting_menu_list > ul li.active a {
    background: #000;
    color: #fff;
}
.setting_menu_list > ul li:last-child a {
    border-bottom: 0;
}
.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}
.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}
.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}
.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}
.myacc_dropmenu li a {color: #fff;}
.myacc_dropmenu .tg-list {position: absolute; top: 0; right: -12px; padding-top: 0;}
.main_tabsec_inner> .nav-tabs>li {width: 33.17%; margin-right: .241545%; position: relative;}
.main_tabsec_inner> .nav-tabs>li:last-child {margin-right: 0;}
.main_tabsec_inner> .nav-tabs {text-align: center;}
.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}
.main_tabsec_inner> .nav-tabs>li.active>a {border: 0; background: #0b0405; color: #fff;}
.main_tabsec_inner> .nav-tabs>li> a > span > b {font-weight: normal;}

.count_num_t {
    background: #a7a7a7;
    width: 60px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #000;
    font-size: 18px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
    border-bottom: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 5px solid #f0f0f0;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight:bold;
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight:bold;
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-weight:bold;
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}
.cur-order-del li a.btn:hover {
    color: #fff;
}
.cur-order-del li span {
    padding-left: 5px;
}
.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}
.calender-icon {
    background-position: -215px -258px;
}
.time-icon {
    background-position: -249px -257px;
}
.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}
.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.cur-ord-rec li a {
    font-size: 14px; text-transform: uppercase; text-decoration: underline; color: #2e2e2c;
}
.cur-ord-rec li a:hover {color: #909090;}
.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}
.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}
.order-cat-hea {background: #fff; padding: 8px 35px; max-width: 331px; margin-bottom: 10px;}
.order-cat h2 {font-size: 20px; color: #0b0405; margin-bottom: 0;}
.order-cat-table {padding: 0 35px;}
.order-cat-table h4 {color: #474747; font-size: 16px; font-weight: 700; margin-bottom: 11px;}
.order-cat-table table {border: 0; max-width: 364px; margin-bottom: 10px;}
.order-cat-table table td {font-weight: 300; font-size: 16px; color: #3d3d3d; padding: 0 5px;}
.order-cat-table table td.p_name {padding-left: 0;}
.p_name {width: 247px;}
.p_val {width: 80px;}
.ord-amount {padding: 10px 0 0 35px; border-top: 1px solid #efefee;}
.ord-amount .row {margin-right: -20px;}
.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}
.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}
.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}
.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}
.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}
.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}
.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}
.congrats::after {display: block; content: ""; clear: both;}
.congrats-inner, .promo-form {margin: 0px auto 40px; text-align: center; padding: 40px 15px; background:url(../images/patterns.jpg) repeat;}
.congrats-inner h3 {font-size: 17px; color: #000; margin-bottom: 5px; letter-spacing: 0.5px; text-transform: capitalize;}
.congrats-inner h2 {    font-size: 40px;  color: #0a1232; margin-bottom: 0; font-weight: bold}

.rewards-tab-section .ord-amount {border-top: inherit; padding-top: 0;}
.rewards-tab-section .order-cat-hea {margin-bottom: 7px;}
.earned_points {max-width: 300px; padding-left: 0; list-style: none; float: right; padding-right: 24px;}
.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}
.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.redeem_popsec {
    max-width: 700px;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 50px auto;
}
.redeem_popsec .promo-earned-content {
    width: 75%; padding: 50px 10px 40px;
}

.redeem_bansec {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.redeem_popsec .redeem_bansec .promo-popup-image {
    width: 240px;
    height: 240px;
    margin: 0 auto;
}
.redeem_popsec .redeem_bansec .promo-earned-content {width: 100%; padding: 30px 30px 30px; text-align: center;margin: 0 auto; }
.redeem_popsec .redeem_bansec .promo-popup-image img { height: 100%; width: 100%; object-fit: cover; object-position: center;}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {width: 25%; height: 270px;}
.redeem_popsec .promo-earned-content h4 {font-size: 32px;max-height: inherit;font-weight: bold}
.redeem_popsec .promo-earned-content h2 {font-size: 42px; color: #3c3c3c;}
.redeem_popsec .promo-earned-content p {margin: 0 0 15px;}
.redeem_popsec .abc_code {padding: 20px 0 0px;}
.redeem_popsec .promo-earned-content h2 span sup {font-size: 26px;}
.redeem_pop_image img {width: 100%; -o-object-fit: cover; object-fit: cover;}
.redeem_popsec .pouup_in {padding: 30px 55px 30px;}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}
.redeem_popsec .btn {
    color: #fff;
    display: block;
    max-width: 220px;
    border-radius: 0px;
    margin: 0 auto; background: #0a1232
}
.redeem_popsec .btn:hover {
    background: #000;
    color: #fff;
}
.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius: 7px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 7px;
    right: 7px;
}
.redeem_popsec .mfp-close:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}

.input_area {
    position: relative;
}
.form-one-next p {
    margin: 5px 0 5px 0;
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
}
.businnes-twobtn .button {
    margin: 0;
    width: 48%;
}

.businnes-twobtn {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
    flex-wrap: wrap;
}

.businnes-twobtn .button:first-child {
    background: #fff;
    color: #000;
}

#details-popup{max-width: 825px;padding: 40px;}
.promo-tab, .vouchers-tab{ height: 400px }
.promo-tab ul, .vouchers-tab ul{
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.promo-tab ul li, .vouchers-tab ul li {
    width: 32%;
    margin: 0 2% 25px 0;
    position: relative;
}
.promo-tab ul li:nth-child(3n), .vouchers-tab ul li:nth-child(3n) {
    margin-right: 0;
}
.white-popup .oc-buttons {
    justify-content: center;
    padding: 20px 0 30px;
    position: relative;
    z-index: 1;
}

.white-popup .oc-buttons:before {
    height: 1px;
    width: 100%;
    content: "";
    background: #d8d5d5;
    position: absolute;
    top: 40px;
}

.white-popup .oc-buttons .button{
    background: #e5e4e4;
    color: #000;
    border-color: #e5e4e4;
    height: 40px;
    margin: 0 4px;
    padding: 5px 25px;
}
.white-popup .oc-buttons .button img{
    filter: invert(100%);
    -webkit-filter: invert(100%);
}

.white-popup .oc-buttons .red-btn {
    background: #db1746;
    border-color: #db1746;
    color: #fff;
}
.white-popup .oc-buttons .red-btn img{
     filter: invert(0);
    -webkit-filter: invert(0);   
}
.cashback-tab ul {
    max-width: 620px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    box-shadow: 0px 8px 15px rgb(2 2 2 / 15%);
    border-radius: 10px;
    overflow: hidden;
}
.cashback-main-content {
    padding: 25px 25px 70px 25px;
    font-size: 13px;
    line-height: 1.5;
    position: relative;
}

.cashback-main-content .arrow-fill-btn {
    width: 100%;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ec194b;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
.cashback-main-content .arrow-fill-btn:hover{
    background: #000;
    border-color: #000;
}
.arrow-fill-btn img{width: 24px; margin-left: 15px;}
.cashback-main-top {
    margin: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cashback-main-top-left {
    padding-left: 45px;
    position: relative;
    width: calc( 100% - 120px );
}
.detail-popup .cashback-main-top-left{
    padding-left: 55px
}
.cashback-main-top-left img {
    position: absolute;
    left: 0;
    top: 5px;
}

.cashback-main-top-left .pmc-name {
    margin-bottom: 0;
}
.cashback-main-top-left .pmc-title{font-size: 18px;     margin-bottom: 0;}
.cashback-image > img{width: 100%;}
.cashback-image {position: relative;}
.cashback-image .like{ right: 50px; height: 30px; width: 30px; }
.myacc-main-div .common-inner-banner{ background: url(../images/admin-bg.jpg) repeat; width: 100%; padding: 60px 20px; text-align: center;  } 
.myacc-main-div .common-inner-banner p{font-size: 38px; text-transform: capitalize; margin-bottom: 10px; font-weight: bold;color: #000}
.form_grouptt{font-size: 15px; font-weight: bold; padding-left: 20px; margin-bottom: 10px }
.half-fg .col-md-6 {
    width: 49%;
}

.half-fg .row {display: flex;justify-content: space-between;flex-wrap: wrap;}

.half-fg .col-md-6 input, .full-fg .col-md-12 input {
    margin: 0;
}
.css-1g6gooi input {height: 36px;}
body .css-vj8t7z{border-radius: 0; border-color: #e4e4e4}
body .react-datepicker-wrapper{display: block}
.btn-last-group {
    text-align: right;
    margin-bottom: 0 !important;
}

.btn-last-group .button {
    margin: 10px 0 0 0;
    background: #000;
    color: #fff;
    border-color: #000; min-width: 170px;
}
.full-fg textarea{ height: 160px }
.cashback-main-top-left .pro-main-option{margin: 0  0 0 10px;}
.cashback-main-top-left .pro-main-option span{margin-bottom: 2px}
.cashback-main-top .pmc-valid {
    width: 120px;
    text-align: right;
}
.promo-new-add ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.promo-new-add .promo-new-ul li {
    width: 32%;
    margin: 0 2% 20px 0;
}

.promo-new-add .promo-new-ul li:nth-child(3n) {
    margin-right: 0;
}
.promo-new-add ul li  .pmc-button .button{
    max-width: 90px;     padding: 7px 10px; margin: 0 3px; font-size: 14px;
}
.add-new-top {
    margin: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-new-top .button {
    margin: 0;
    border-color: #000000;
    color: #fff;
    background: #000000;
    padding: 9px 25px;
}
.mbacc_mslidersec{ display: none; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px;}

.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a, .mobile-catgegory-nav .slick-track .slick-slide a{ display: flex; text-align: center; color: #000; font-size: 16px; padding: 12px 10px; background: #e4e4e4; text-transform: uppercase;font-weight: normal; align-items: center; justify-content: center;line-height: 1; font-weight: 600;}
.mbacc_mslide.active a, .mobile-catering-slider .slick-track .active a, .mobile-catgegory-nav .slick-track .active a{ background: #13612d; color: #fff; }
.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow, .mobile-catgegory-nav .slick-arrow{ background: #8d8d8d; border: 0; width: 30px; height: 100%; position: absolute; left: 0px; top: 0; bottom: 0; margin: auto 0; text-align: center; overflow: hidden; font-size: 0;color: #fff; z-index: 1;padding: 0; min-width: inherit;border-radius: 0}
.mbacc_mslidersec .slick-next, .mobile-catgegory-nav .slick-next{ right: 0px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before, .mobile-catgegory-nav .slick-arrow:before{ content: "\f104"; display: inline-block; font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; line-height: 25px; font-size: 24px; }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before, .mobile-catgegory-nav .slick-next:before{ content: "\f105";}
.custom-alrt-popupcls{padding: 0}
.promo-new-add .btn-last-group .button {
    width: 48.5%;
    margin: 0;
    height: 50px;
}
.promo-new-add .btn-last-group {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}
.mCSB_scrollTools{
width: 4px
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width: 4px; background-color: #000000;}
.mCSB_scrollTools .mCSB_draggerRail{width: 4px;background-color: #e8e7e7;}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000000;
}

.acc-inform {
    border-bottom: 1px solid #e4e4e4;
    margin: 0 0 40px 0;
    padding: 0 0 30px 0;
}
.btn-last-group.voc-btg {
    justify-content: flex-end;
}
.voc-btg .myaccount_update.button{margin-right: 10px; }
.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}
.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

.outlet-listing-rhs > ul > li.no_restaurants {
    border: 0;
    padding: 25px;
    width: 100%;
    display: block;
    text-align: center;
    box-shadow: none;
}
.outlet-listing-rhs > ul > li.no_restaurants h3 {
    font-weight: bold;
    margin-bottom: 15px;
}
.view-image {
    font-weight: 600;
    display: inline-flex;
    margin: 10px 0 5px 15px;
    border-bottom: 2px solid #E4E4E4;
    color: #000000;
}
.css-10nd86i {
    z-index: 3;
}
.mobile-login-section li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-list-button > div {
    width: 49%;
    line-height: 1;
    height: 41px;
}

.mobile-login-section {
    background: #f4f3f3;
        border-radius: 8px;
}

.mobile-login-section a {
    padding: 13px 0;
}
.mobile-login-section .login-menu span{ color: #000; }




















